import React from 'react';
// import GlobalComp from '../global/globalComponent';

function NotFound() {
   
    return (
        <>
        
            <div className='thankyou'>
                <div className="container">
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="thankyou_wrap">
                                <h1 className='thankyou_hdng'>Thank You !</h1>
                                <p>Your Submission has been received. We will be in touch and contact you soon!</p>
                                <a href="/" class="btn">Back To Home</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default NotFound;
